import HomePageBgAllInOne from "src/ui/assets/images/home-page-bg-all-in-one.webp";
import HomePageBgHWJ from "src/ui/assets/images/home-page-bg-hwj.webp";
import HomePageBgPrevention from "src/ui/assets/images/home-page-bg-prevention.webp";

import {
  LabOrderControllerService,
  TaskResponse,
  UpdateTaskRequest
} from "@9amhealth/openapi";
import { Blac, Cubit } from "blac-next";
import {
  AppPopup,
  AppQueryPopupsController
} from "components/AppQueryPopups/AppQueryPopupsBloc";
import { OpenBrowser } from "src/hybrid/components/Browser";
import { DateFormats, dateLocal } from "src/lib/date";
import { featureFlags } from "src/lib/featureFlags";
import { getSupportedUserLanguage } from "src/lib/i18next";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { CustomQuestionnaireResult } from "src/state/QuestionnaireCubit/QuestionnaireState";
import {
  appViewState,
  taskManagementState,
  toast,
  tracker,
  userState
} from "src/state/state";
import {
  TaskKey,
  TaskObserverEvent,
  TaskResponseKnown
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import { ProfileProgramMembership } from "src/state/UserCubit/UserCubit";
import { TranslationKey } from "src/types/translationKey";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import ConfirmingAppointmentInfoBloc from "src/ui/components/ConfirmingAppointmentInfo/ConfigmingAppointmentInfoBloc";
import {
  Step,
  StepStatus,
  Variables
} from "src/ui/components/StepList/StepList";
import AppointmentsBloc from "../AppointmentsBloc/AppointmentsBloc";
import status = UpdateTaskRequest.status;
import { AvailableLabTestsResponse } from "@9amhealth/openapi";

export interface OnboardingBlocState {
  loading: boolean;
  ctaText: TranslationKey;
  steps: Step[];
  title?: TranslationKey;
  backgroundImage?: string;
  variables?: Variables;
}

export type VisitType =
  | "DIETITIAN_VISIT"
  | "PHARMACIST_VISIT"
  | "SYNC_VISIT"
  | "COACHING_1ON1"
  | "ONBOARDING_SPECIALIST"
  | "CCS_MED_CONSULT"
  | "LAB_RESULTS_REVIEW";

export const onboardingCompletedTask = {
  program: "onboarding",
  group: "user-experience",
  slug: "completed-onboarding"
};

export default class OnboardingBloc extends Cubit<OnboardingBlocState> {
  constructor() {
    super({
      loading: false,
      ctaText: "button.continue",
      steps: []
    });
    void this.init();
  }

  init = async () => {
    this.checkProgramMembership();
    this.updateSteps();
    this.setOnboardingExperienceCompleted();
  };

  get labQuestionnaireTask() {
    return taskManagementState.getTask(TaskKey.INITIAL_LAB_QUESTIONNAIRE);
  }
  get labOrderTask() {
    return taskManagementState.getTask(TaskKey.COMPLETE_INITIAL_LAB_ORDER);
  }
  get uploadLabsTask() {
    return taskManagementState.getTask(TaskKey.UPLOAD_LABS);
  }
  get initialSyncVisitTask() {
    return taskManagementState.getTask(TaskKey.INITIAL_SYNC_VISIT);
  }

  loadLabOrderProviders = async () => {
    try {
      const response =
        await LabOrderControllerService.availableLabOrderProviders();
      const labProviders = response.data.labOrderProviders as string[];

      return labProviders;
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
  };

  /** Update the subtitle of the get your labs done step */
  subtitleStepGetYourLabsDone = (): TranslationKey | undefined => {
    const labStepStatus = this.statusStepGetYourLabsDone();
    const labInformation = this.checkLabsAppointment();

    if (
      labStepStatus === StepStatus.IN_PROGRESS &&
      labInformation.provider === "GETLABS" &&
      labInformation.appointmentId
    ) {
      const appointment = Blac.getBloc(AppointmentsBloc).appointments.filter(
        (app) => app.id === labInformation.appointmentId
      )[0];

      if (appointment) {
        const userLanguage = getSupportedUserLanguage();
        const { start, end } = appointment ?? {};
        const weekday = dateLocal(start, { locale: userLanguage }).format(
          DateFormats.DISPLAY_WEEKDAY_MONTH_DAY
        );

        const timeZone = dateLocal(start, { locale: userLanguage }).format("z");
        const startTime = dateLocal(start, { locale: userLanguage }).format(
          DateFormats.DISPLAY_TIME_ALT
        );
        const endTime = dateLocal(end, { locale: userLanguage }).format(
          DateFormats.DISPLAY_TIME_ALT
        );

        this.patch({
          variables: {
            weekday,
            startTime,
            endTime,
            timeZone
          }
        });
        return "getlabs.eventDateTime";
      }
    }

    if (labStepStatus === StepStatus.PENDING) {
      return "onboardingSteps.subtitle.medicalReview";
    }
    return undefined;
  };

  get initialSyncVisitEnabled(): boolean {
    const task = this.initialSyncVisitTask;
    const visitType = this.visitTypeScheduleFirstVisit(task);
    const schedulingDisabled =
      this.checkSchedulingDisabledFeatureFlag(visitType);

    return Boolean(visitType && !schedulingDisabled);
  }

  /** Update the title of the schedule first visit step */
  titleStepScheduleFirstVisit = (): TranslationKey => {
    // if there's no visit type or flag is disabled, show Start Journey
    if (this.initialSyncVisitEnabled) {
      return "onboardingSteps.step.scheduleFirstVisit";
    }

    return "onboardingSteps.step.startJourney";
  };

  /** Update the steps based on the current state */
  updateSteps = () => {
    const accountSetupStatus = this.statusStepAccountSetup();
    const labsStatus = this.statusStepGetYourLabsDone();
    const firstVisitStatus = this.statusStepScheduleFirstVisit();

    const labsStatusShown =
      accountSetupStatus === StepStatus.COMPLETED
        ? labsStatus
        : StepStatus.LOCKED;

    const firstVisitStatusShown =
      labsStatusShown === StepStatus.COMPLETED ||
      labsStatusShown === StepStatus.SKIPPED
        ? firstVisitStatus
        : StepStatus.LOCKED;

    const firstVisitTitle = this.titleStepScheduleFirstVisit();

    const steps: Step[] = [
      {
        status: StepStatus.COMPLETED,
        title: "onboardingSteps.step.completeRegistration"
      },
      {
        status: accountSetupStatus,
        title: "onboardingSteps.step.finishAccountSetup"
      },
      {
        status: labsStatusShown,
        title: "onboardingSteps.step.getYourLabsDone",
        subtitle: this.subtitleStepGetYourLabsDone(),
        showStatus: this.getShowStatus()
      },
      {
        status: firstVisitStatusShown,
        title: firstVisitTitle
      }
    ];

    this.patch({ steps, ctaText: this.getCtaText() });
  };

  checkLabsAppointment = (): {
    provider?: AvailableLabTestsResponse.labOrderProvider;
    appointmentId?: string;
    schedulingUrl?: string;
    requisitionFileId?: string;
    location?: string;
  } => {
    const { labOrderTask } = this;
    return {
      provider: (labOrderTask?.additionalData?.provider ||
        labOrderTask?.additionalData?.labOrderProvider) as
        | AvailableLabTestsResponse.labOrderProvider
        | undefined,
      appointmentId: labOrderTask?.additionalData?.appointmentId as
        | string
        | undefined,
      schedulingUrl: labOrderTask?.additionalData?.appointmentUrl as
        | string
        | undefined,
      requisitionFileId: labOrderTask?.additionalData?.requisitionFileId as
        | string
        | undefined,
      location: labOrderTask?.additionalData?.labOrderPscLocationName as
        | string
        | undefined
    };
  };

  /** Check if the task status is final */
  isTaskStatusFinal = (task: TaskResponseKnown | undefined): boolean => {
    return taskManagementState.isTaskStatusFinal(task);
  };

  /** Show status */
  getShowStatus = (): boolean => {
    const labsStatus = this.statusStepGetYourLabsDone();
    if (
      labsStatus === StepStatus.PENDING ||
      labsStatus === StepStatus.SKIPPED
    ) {
      return true;
    }
    return false;
  };

  /** Mark onboarding completed if all steps are completed and scheduling first visit is either COMPLETED or SKIPPED */
  setOnboardingExperienceCompleted = () => {
    const accountSetupStatus = this.statusStepAccountSetup();
    const labsStatus = this.statusStepGetYourLabsDone();
    const visitStatus = this.statusStepScheduleFirstVisit();

    if (
      accountSetupStatus === StepStatus.COMPLETED &&
      (labsStatus === StepStatus.COMPLETED ||
        labsStatus === StepStatus.SKIPPED) &&
      visitStatus === StepStatus.COMPLETED
    ) {
      void this.setOnboardingCompletedTaskCompleted();
      tracker.track("Onboarding Completed");
    }
  };

  /** Get the CTA text based on the current state */
  getCtaText = (): TranslationKey | undefined => {
    const accountSetupStatus = this.statusStepAccountSetup();
    const labsStatus = this.statusStepGetYourLabsDone();
    const labTask = this.labOrderTask;
    const visitStatus = this.statusStepScheduleFirstVisit();

    if (accountSetupStatus === StepStatus.IN_PROGRESS) {
      return "button.continue";
    }

    // labs
    if (labTask?.status === TaskResponse.status.IN_PROGRESS) {
      const labsTaskAction = this.getLabsTaskAction();

      switch (labsTaskAction.action) {
        case "appointment-details":
          return "button.seeAppointmentDetails";
        case "schedule-visit":
          return "button.scheduleAtHomeLabs";
        case "labcorp-information":
        case "kwiktrip-information":
        case "quest-information":
        case "probably-tasso":
          return "button.seeInstructions";
      }
    }

    if (labsStatus === StepStatus.PENDING) {
      const { labOrderTask, labQuestionnaireTask } = this;
      if (
        this.isTaskStatusFinal(labQuestionnaireTask) &&
        (labOrderTask?.status === TaskResponse.status.LOCKED ||
          labOrderTask?.status === TaskResponse.status.AVAILABLE)
      ) {
        return;
      }
    }

    // initial visit cta text
    if (
      this.initialSyncVisitEnabled &&
      accountSetupStatus === StepStatus.COMPLETED &&
      (labsStatus === StepStatus.COMPLETED ||
        labsStatus === StepStatus.SKIPPED) &&
      visitStatus === StepStatus.IN_PROGRESS
    ) {
      return "button.scheduleNow";
    }

    return "button.continue";
  };

  openSchedulerDetails?: {
    openedAt: number;
    task: TaskResponseKnown;
  };

  /** Open the scheduler for the first visit */
  openScheduler = () => {
    const task = this.initialSyncVisitTask;

    if (!task) {
      reportErrorSentry(new Error("No initial sync visit task found"));
      return;
    }

    // get the scheduling URL from the task, scheduling_url is the new field, popup_url is the old one before we updated the tasks
    const url =
      task.additionalData?.scheduling_url?.toString() ??
      task.additionalData?.popup_url?.toString();

    if (!url) {
      toast.error("error.noSchedulingUrl");
      reportErrorSentry(new Error("No scheduling URL found"));
      return;
    }

    AppQueryPopupsController.openPopup(AppPopup.iframe, {
      additionalParameters: {
        url,
        title: translate("onboardingSteps.step.scheduleFirstVisit"),
        stay: "false"
      },
      onEvent: {
        popupClosed: () => void this.handleSchedulerPopupClosed()
      }
    });

    this.openSchedulerDetails = {
      openedAt: Date.now(),
      task
    };
  };

  checkUserScheduledAppointment = async (
    fetchTasks: boolean
  ): Promise<boolean> => {
    if (fetchTasks) {
      await taskManagementState.loadProgramTasks(KnownProgram.ONBOARDING);
    }
    const initialSyncVisitTask = taskManagementState.getTask(
      TaskKey.INITIAL_SYNC_VISIT
    );
    const initialSyncVisitStatus = initialSyncVisitTask?.status;

    const completedStates = [
      TaskResponse.status.COMPLETED,
      TaskResponse.status.SKIPPED
    ];

    if (
      initialSyncVisitStatus &&
      completedStates.includes(initialSyncVisitStatus)
    ) {
      return true;
    }

    return false;
  };

  handleSchedulerPopupClosed = async () => {
    if (!this.openSchedulerDetails) {
      return;
    }
    const expectNewType =
      this.openSchedulerDetails.task.additionalData?.appointment_type;

    if (!expectNewType) {
      return;
    }

    const hasBeenOpenTimeSeconds =
      (Date.now() - this.openSchedulerDetails.openedAt) / 1000;
    // if the popup has been open for less than 10 seconds, the user probably didnt schedule, no need to show the confirming appointment info
    if (hasBeenOpenTimeSeconds < 10) {
      return;
    }

    BlockingLoadingOverlayController.startLoading({
      bg: "transparent",
      fadeIn: false
    });
    const isScheduled = await this.checkUserScheduledAppointment(true);
    BlockingLoadingOverlayController.endLoading();

    if (isScheduled) {
      void this.setOnboardingCompletedTaskCompleted();
      tracker.track("Onboarding Completed");
      return;
    }

    const confirmInfoBloc = Blac.getBloc(ConfirmingAppointmentInfoBloc);
    confirmInfoBloc.setShowConfirmInfo(true);

    // check with polling, in case websockets are not available
    const intervalPolling = setInterval(() => {
      this.checkUserScheduledAppointment(true)
        .then((isScheduled) => {
          if (isScheduled) {
            clearInterval(intervalPolling);
            confirmInfoBloc.setShowConfirmInfo(false);
            void this.setOnboardingCompletedTaskCompleted();
            tracker.track("Onboarding Completed");
          }
        })
        .catch(() => {
          clearInterval(intervalPolling);
          confirmInfoBloc.setShowConfirmInfo(false);
        });
    }, 5000);

    // check with websockets
    const observer = taskManagementState.addObserver(
      TaskObserverEvent.TASK_LIST_CHANGED,
      () => {
        this.checkUserScheduledAppointment(false)
          .then((isScheduled) => {
            if (isScheduled) {
              confirmInfoBloc.setShowConfirmInfo(false);
              clearInterval(intervalPolling);
              taskManagementState.removeObserver(observer);
              void this.setOnboardingCompletedTaskCompleted();
              tracker.track("Onboarding Completed");
            }
          })
          .catch(() => {
            confirmInfoBloc.setShowConfirmInfo(false);
            clearInterval(intervalPolling);
            taskManagementState.removeObserver(observer);
          });
      }
    );
  };

  /* Check if the scheduling is disabled for certain visit types */
  checkSchedulingDisabledFeatureFlag = (type?: VisitType): boolean => {
    switch (type) {
      case "DIETITIAN_VISIT":
        return featureFlags.getFlag(
          "disable_scheduling_for_onboarding_initial_visit_rd"
        );

      case "PHARMACIST_VISIT":
        return featureFlags.getFlag(
          "disable_scheduling_for_onboarding_initial_visit_pharmacist"
        );

      case "SYNC_VISIT":
        return featureFlags.getFlag(
          "disable_scheduling_for_onboarding_initial_visit_md"
        );

      default:
        return false;
    }
  };

  static onboardingTasksToShow = [
    TaskKey.PHARMACY_INSURANCE,
    TaskKey.MEDICAL_INSURANCE,
    TaskKey.CKECKIN_QUESTIONNAIRE,
    TaskKey.SELECT_PCP,
    TaskKey.PREFERRED_PHARMACY
  ];

  /** Get the status of the account setup step */
  statusStepAccountSetup = (): StepStatus => {
    const tasks = OnboardingBloc.onboardingTasksToShow
      .map((task) =>
        taskManagementState.state.tasks.find(
          (t) => t.program === KnownProgram.ONBOARDING && t.slug === task
        )
      )
      .filter((task) => task);

    const allInFinalState = tasks.every(this.isTaskStatusFinal);

    if (allInFinalState) {
      return StepStatus.COMPLETED;
    }

    return StepStatus.IN_PROGRESS;
  };

  /** Get the status of the get your labs done a step */
  statusStepGetYourLabsDone = (): StepStatus => {
    const { labQuestionnaireTask, labOrderTask, uploadLabsTask } = this;

    // if q. is final and labs status is final, user did everything and user does not need to do labs
    if (
      this.isTaskStatusFinal(labQuestionnaireTask) &&
      this.isTaskStatusFinal(labOrderTask)
    ) {
      if (labOrderTask?.status === "SKIPPED") {
        return StepStatus.SKIPPED;
      }
      return StepStatus.COMPLETED;
    }

    // if q. is final, and labs status is locked we are waiting for connective
    // if q. is final, and labs status is available, waiting for req. form upload from care team
    if (
      this.isTaskStatusFinal(labQuestionnaireTask) &&
      this.isTaskStatusFinal(uploadLabsTask) &&
      (labOrderTask?.status === TaskResponse.status.LOCKED ||
        labOrderTask?.status === TaskResponse.status.AVAILABLE)
    ) {
      return StepStatus.PENDING;
    }

    // if the questionnaire is in progress, the step is in progress
    // or if the questionnaire is done, and the lab order is not started, the step is in progress
    if (this.statusStepAccountSetup() === StepStatus.COMPLETED) {
      return StepStatus.IN_PROGRESS;
    }

    return StepStatus.LOCKED;
  };

  /** Get the visit type of the schedule first visit step */
  visitTypeScheduleFirstVisit = (
    task: TaskResponseKnown | undefined
  ): VisitType | undefined => {
    // visit is unavailable for the user if the status is locked or skipped
    if (
      task?.status === TaskResponse.status.SKIPPED ||
      task?.status === TaskResponse.status.COMPLETED
    ) {
      return undefined;
    }

    const taskType = task?.additionalData?.appointment_type;
    if (taskType) {
      return taskType as VisitType;
    }
  };

  /** Get the status of the schedule first visit step */
  statusStepScheduleFirstVisit = (): StepStatus => {
    // if the get your labs done a step is not completed, the step is not available
    if (
      this.statusStepGetYourLabsDone() !== StepStatus.COMPLETED &&
      this.statusStepGetYourLabsDone() !== StepStatus.SKIPPED
    ) {
      return StepStatus.LOCKED;
    }

    if (
      this.initialSyncVisitTask?.status === TaskResponse.status.COMPLETED ||
      this.initialSyncVisitTask?.status === TaskResponse.status.SKIPPED
    ) {
      return StepStatus.COMPLETED;
    }

    return StepStatus.IN_PROGRESS;
  };

  /** Check the user's program membership and update the title and background image */
  checkProgramMembership = () => {
    const { programMemberships } = userState;
    const activeProgram = programMemberships?.find(
      (program) => program.active || (!program.start && !program.end)
    )?.program;
    const notActiveYetProgram = programMemberships?.[0]?.program;

    switch (activeProgram || notActiveYetProgram) {
      case ProfileProgramMembership.HEALTHY_WEIGHT_JOURNEY:
        this.patch({
          title: "onboardingSteps.title.healthyWeightJourney",
          backgroundImage: HomePageBgHWJ
        });
        break;

      case ProfileProgramMembership.DIABETES_AND_HEART_DISEASE_PREVENTION:
        this.patch({
          title: "program.title_DIABETES_AND_HEART_DISEASE_PREVENTION",
          backgroundImage: HomePageBgPrevention
        });
        break;

      case ProfileProgramMembership.ALLINONE_HEALTHCARE_CONCIERGE:
        this.patch({
          title: "program.title_ALLINONE_HEALTHCARE_CONCIERGE",
          backgroundImage: HomePageBgAllInOne
        });
        break;

      default:
        this.patch({
          title: "onboardingSteps.title.healthyWeightJourney",
          backgroundImage: HomePageBgHWJ
        });
        break;
    }
  };

  /** Check if the user has access to the fast pass
   * if the initial sync visit task has `skipable: true`, we can skip it
   * */
  checkFastPassAccess = (): boolean => {
    const { canBeSkippedByUser } =
      this.initialSyncVisitTask?.additionalData ?? {};
    console.log("canBeSkippedByUser", canBeSkippedByUser);
    return Boolean(canBeSkippedByUser);
  };

  /** Skip the initial sync visit task */
  skipInitialSyncVisitTask = async () => {
    try {
      const task = this.initialSyncVisitTask;

      if (!task) {
        reportErrorSentry(
          new Error("Initial sync visit task is required to skip it")
        );

        return;
      }

      await taskManagementState.updateTaskStatus(
        {
          program: task.program,
          group: task.group,
          slug: task.slug
        },
        status.SKIPPED
      );
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
  };

  /** Set the onboarding completed task to complete */
  setOnboardingCompletedTaskCompleted = async () => {
    try {
      await taskManagementState.updateTaskStatus(
        onboardingCompletedTask,
        status.IN_PROGRESS
      );
      appViewState.setShowOnboardingScreen(false);
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
  };

  /** Handle the CTA press for the schedule first visit step */
  ctaActionStepScheduleFirstVisit = () => {
    const task = this.initialSyncVisitTask;
    const type = this.visitTypeScheduleFirstVisit(task);

    // if there's no visit type or flag is disabled, skip the task, mark onboarding completed
    if (!this.initialSyncVisitEnabled) {
      void this.skipInitialSyncVisitTask();
      void this.setOnboardingCompletedTaskCompleted();
      return;
    }

    if (!type) {
      return;
    }

    if (["SYNC_VISIT"].includes(type)) {
      this.openScheduler();
      tracker.track("Onboarding Open Scheduler initial visit", {
        data: { type, from: "cta" }
      });
      return;
    }

    if (this.checkFastPassAccess()) {
      AppQueryPopupsController.openPopup(AppPopup.fastPass, {
        additionalParameters: {
          stay: "false"
        },
        onEvent: {
          "skip-visit": () => {
            void this.skipInitialSyncVisitTask();
            void this.setOnboardingCompletedTaskCompleted();
            AppQueryPopupsController.closePopup();
            tracker.track("Onboarding Skip initial visit", {
              data: { type, from: "fastpass" }
            });
          },
          "schedule-visit": () => {
            this.openScheduler();
            tracker.track("Onboarding Open Scheduler initial visit", {
              data: { type, from: "fastpass" }
            });
          }
        }
      });
      tracker.track("Onboarding Open Fastpass initial visit", {
        data: { type }
      });
      return;
    }

    this.openScheduler();
    tracker.track("Onboarding Open Scheduler initial visit", {
      data: { type, from: "cta" }
    });
  };

  /** Handle the CTA press for the account setup step */
  ctaActionStepAccountSetup = () => {
    AppQueryPopupsController.openPopup(AppPopup.onboardingTasks);
  };

  getLabsTaskAction = () => {
    const { appointmentId, requisitionFileId, schedulingUrl, location } =
      this.checkLabsAppointment();
    const taskIsInProgress =
      this.labOrderTask?.status === TaskResponse.status.IN_PROGRESS;

    if (appointmentId) {
      return {
        action: "appointment-details",
        appointmentId
      } as const;
    }

    if (schedulingUrl) {
      return {
        action: "schedule-visit",
        schedulingUrl
      } as const;
    }

    if (requisitionFileId) {
      if (
        this.labOrderTask?.additionalData?.labOrderProvider ===
        AvailableLabTestsResponse.labOrderProvider.KWIK_TRIP_CENTER_FOR_HEALTH
      ) {
        return {
          action: "kwiktrip-information",
          requisitionFileId,
          location
        } as const;
      } else if (
        this.labOrderTask?.additionalData?.labOrderProvider ===
        AvailableLabTestsResponse.labOrderProvider.QUEST
      ) {
        return {
          action: "quest-information",
          requisitionFileId
        } as const;
      }
      return {
        action: "labcorp-information",
        requisitionFileId
      } as const;
    }

    if (taskIsInProgress) {
      return {
        action: "probably-tasso"
      } as const;
    }

    return {
      action: "unknown"
    } as const;
  };

  /** Handle CTA for initial lab order */
  ctaActionStepGetYourLabsDone = () => {
    const { labOrderTask, uploadLabsTask, labQuestionnaireTask } = this;
    const labsTaskAction = this.getLabsTaskAction();

    if (labOrderTask?.status === TaskResponse.status.IN_PROGRESS) {
      if (labsTaskAction.action === "appointment-details") {
        AppQueryPopupsController.openPopup(AppPopup.appointment, {
          additionalParameters: {
            id: labsTaskAction.appointmentId
          }
        });
        tracker.track("Onboarding Open Labs Appointment Details");
        return;
      } else if (labsTaskAction.action === "schedule-visit") {
        void OpenBrowser(labsTaskAction.schedulingUrl, {
          presentationStyle: "popover",
          useBaseUrl: false
        });
        tracker.track("Onboarding Open Labs Schedule Visit");
        return;
      } else if (labsTaskAction.action === "labcorp-information") {
        AppQueryPopupsController.openPopup(AppPopup.labInstructions, {
          additionalParameters: {
            fileId: labsTaskAction.requisitionFileId,
            provider: AvailableLabTestsResponse.labOrderProvider.LABCORP
          }
        });
        tracker.track("Onboarding Open Labs Labcorp Information");
        return;
      } else if (labsTaskAction.action === "kwiktrip-information") {
        AppQueryPopupsController.openPopup(AppPopup.labInstructions, {
          additionalParameters: {
            fileId: labsTaskAction.requisitionFileId,
            provider:
              AvailableLabTestsResponse.labOrderProvider
                .KWIK_TRIP_CENTER_FOR_HEALTH,
            location: labsTaskAction.location ?? ""
          }
        });
        tracker.track("Onboarding Open Labs Kwik Trip Information");
        return;
      } else if (labsTaskAction.action === "quest-information") {
        AppQueryPopupsController.openPopup(AppPopup.labInstructions, {
          additionalParameters: {
            fileId: labsTaskAction.requisitionFileId,
            provider: AvailableLabTestsResponse.labOrderProvider.QUEST
          }
        });
        tracker.track("Onboarding Open Labs Quest Information");
        return;
      } else if (labsTaskAction.action === "probably-tasso") {
        AppQueryPopupsController.openPopup(AppPopup.article, {
          additionalParameters: {
            url: "https://join9am.com/blog/how-to-use-the-tasso-device-to-collect-your-blood-sample",
            title: "TASSO"
          }
        });
        tracker.track("Onboarding Open TASSO info");
        return;
      }
    }

    // if the questionnaire task is not final, open the Questionnaire
    const questionnaireFinal = this.isTaskStatusFinal(labQuestionnaireTask);
    const uploadLabsFinal = this.isTaskStatusFinal(uploadLabsTask);
    const labTaskPending =
      labOrderTask?.status === TaskResponse.status.LOCKED ||
      labOrderTask?.status === TaskResponse.status.AVAILABLE;
    if (!questionnaireFinal || !uploadLabsFinal || labTaskPending) {
      AppQueryPopupsController.openPopup(AppPopup.initialLab);
      tracker.track("Onboarding Open Initial Lab Dialog", {
        data: {
          questionnaireFinal,
          uploadLabsFinal
        }
      });
      return;
    }

    appViewState.navigate("/app/chat");
  };

  /** Handle the CTA press
   * Depending on the status of the steps, do different things
   * */
  handleCtaPress = () => {
    const setupAccountStatus = this.statusStepAccountSetup();
    const labsStatus = this.statusStepGetYourLabsDone();
    const scheduleFirstVisitStatus = this.statusStepScheduleFirstVisit();

    const initialSyncInFinalStatus = taskManagementState.isTaskStatusFinal(
      this.initialSyncVisitTask
    );

    // POST SIGN UP Tasks
    if (setupAccountStatus === StepStatus.IN_PROGRESS) {
      this.ctaActionStepAccountSetup();
      tracker.track("Onboarding Account Setup CTA Pressed");
      return;
    }

    // LABS
    if (
      labsStatus === StepStatus.IN_PROGRESS ||
      labsStatus === StepStatus.PENDING
    ) {
      this.ctaActionStepGetYourLabsDone();
      tracker.track("Onboarding Get Your Labs Done CTA Pressed");
      return;
    }

    // FIRST VISIT
    if (
      scheduleFirstVisitStatus === StepStatus.IN_PROGRESS &&
      !initialSyncInFinalStatus
    ) {
      this.ctaActionStepScheduleFirstVisit();
      tracker.track("Onboarding Schedule First Visit CTA Pressed");
      return;
    }

    reportErrorSentry(new Error("No action for CTA press"), {
      setupAccountStatus,
      labsStatus,
      scheduleFirstVisitStatus
    });
  };

  /** Check if the user selected the other provider option in the initial lab questionnaire */
  checkUserSelectedOtherProvider = (
    data: CustomQuestionnaireResult
  ): boolean => {
    const PROVIDER_QUESTION_ID = "lXCczllBjyLh";
    const PROVIDER_CHOICE_OTHER_ID = "TAeIktkwrrHe";
    const userSelectedOtherProvider = data.answers.json.find((answer) => {
      const questionMatch = answer.questionId === PROVIDER_QUESTION_ID;
      if (
        questionMatch &&
        typeof answer.fieldValue === "object" &&
        "choiceId" in answer.fieldValue
      ) {
        return answer.fieldValue.choiceId === PROVIDER_CHOICE_OTHER_ID;
      }
      return false;
    });

    return Boolean(userSelectedOtherProvider);
  };

  handleInitialLabQuestionnaireCompleted = async () => {
    // update tasks from the server
    await taskManagementState.loadProgramTasks(KnownProgram.ONBOARDING);
  };
}
